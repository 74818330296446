import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { H1 as BaseH1 } from 'components/Typography';
import Slices from 'components/Slices';
import Container from 'react-bootstrap/Container';
import { extractPageStyles, extractSocialMeta } from 'utils/fieldExtractors';
import styled from 'styled-components/macro';

const H1 = styled(BaseH1)`
  margin-top: 2rem;
`;

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function BlogPost({ data, ...props }) {
  const doc = data.prismic.blogPost;
  if (!doc) {
    return null;
  }
  const { _meta, seoTitle, seoMetaDescription, pageTitle, body } = doc;

  const pageName = pageTitle ? pageTitle[0].text : '';

  return (
    <Layout pageStyles={extractPageStyles(doc)}>
      <SEO
        title={seoTitle || pageName}
        description={seoMetaDescription}
        lang={_meta}
        socialMeta={extractSocialMeta(doc)}
      />
      <Container>
        <H1>{pageName}</H1>
      </Container>
      <Slices slices={body} {...props} />
    </Layout>
  );
}

export const query = graphql`
  query BlogPostQuery($uid: String!, $lang: String!) {
    prismic {
      blogPost: blog_post(uid: $uid, lang: $lang) {
        pageTitle: page_title
        seoTitle: seo_title
        seoMetaDescription: seo_meta_description
        ogTitle: og_title
        ogImage: og_image
        ogDescription: og_description
        twitterCard: twitter_card
        twitterImage: twitter_image
        twitterTitle: twitter_title
        twitterDescription: twitter_description
        pageBackgroundColor: page_background_color
        pageBodyTextColor: page_body_text_color
        _meta {
          lang
        }
        body {
          ...BlogPostTextBlockFragment
          ...BlogPostTableFragment
          ...BlogPostBasicCtaFragment
          ...BlogPostCtaBannerFragment
          ...BlogPostFeaturedImageLeftRightFragment
          ...BlogPostCarouselFragment
          ...BlogPostBlockStyleFragment
        }
      }
    }
  }
`;

BlogPost.propTypes = propTypes;
BlogPost.defaultProps = defaultProps;

export default BlogPost;
